import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import React from 'react';
import { getRowClassName } from 'pages/OpsCostReport/services/utils';
import { useIndividualTenantCostsSection as hook } from './useIndividualTenantCostsSection';
import AddRowButtonAndDescription from '../../../AddRowButtonAndDescription/AddRowButtonAndDescription';
import IndividualTenantCostsTableSummary from './components/IndividualTenantCostsTableSummary';
import { MissingHiddenAccountCodeAlert } from '../components/MissingHiddenAccountCodeAlert/MissingHiddenAccountCodeAlert';
import { InvalidManualAmountsAlert } from '../components/InvalidManualAmountsAlert';


type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetIndividualTenantCostsSectionProps = ReturnTypeOfHook['getIndividualTenantCostsSectionProps'];
export type ReturnTypeOfIndividualTenantCostsHook = ReturnType<GetIndividualTenantCostsSectionProps>;


interface IndividualTenantCostsSectionProps {
  sectionNumber: number;
  tl: any;
  useIndividualTenantCostsSection: ReturnTypeOfIndividualTenantCostsHook;
}


const IndividualTenantCostsSection = ({
  sectionNumber,
  tl,
  useIndividualTenantCostsSection,
}: IndividualTenantCostsSectionProps) => {
  const {
    columns,
    dataSource,
    loading,
    getTotalIncludingManualAmounts,
    contractId,
    missingHidddenAccountCodes,
  } = useIndividualTenantCostsSection;


  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(dataSource) ? { y: 200 } : undefined;

  return (
    <SubSectionWrapper
      title={`${sectionNumber}.3 ${tl(opsCostTranslation.report.sections.expensesSection.individualTenantCostSubSection.title)}`}
      subSectionSuffix={<AddRowButtonAndDescription section="individualTenantCosts" contractId={contractId} disabled={loading} />}
    >
      <MissingHiddenAccountCodeAlert
        contractId={contractId}
        missingHidddenAccountCodes={missingHidddenAccountCodes}
        tl={tl}
        sectionName="individualTenantCosts"
      />
      <InvalidManualAmountsAlert
        tl={tl}
        contractId={contractId}
        section="individualTenantCosts"
      />
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          className="table-with-input-rows"
          rowClassName={record => getRowClassName(record.isHiddenRow, record.isManualRow)}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={tableHeight}
          rowKey="key"
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <IndividualTenantCostsTableSummary
              title={tl(opsCostTranslation.report.sections.expensesSection.otherOpsCostSubSection.subtotal)}
              getTotalIncludingManualAmounts={getTotalIncludingManualAmounts}
              nrTotalColumns={columns.length}
              addPlaceholderLastColumn
              contractId={contractId}
            />
          )}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default IndividualTenantCostsSection;
