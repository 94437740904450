import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ContractLegacyControllerApi,
  ContractProjectionDto,
  GetContractsUsingGETTypeEnum,
  PropertyLegacyControllerApi,
  PropertyLegacyDto,
  PropertyLegacyDtoAdministrationTypeEnum,
  RentPlanAmountDto,
  RentPlanControllerApi,
  RentPlanCreateDto,
  RentPlanCreateDtoRentTypeEnum,
  UnitLegacyControllerApi,
  UnitLegacyDto,
} from 'api/accounting';
import { AuthContext } from 'contexts/AuthContext';
import { LanguageContext } from 'contexts/LanguageContext';
import { showNotification } from 'lib/Notification';
import {
  getGermanMonthNumber,
  round2dec,
} from 'lib/Utils';
import _, { isNil } from 'lodash';
import moment from 'moment';
import {
  sortUnitContracts,
} from 'services/UnitContractsList/useUnitContractUtils';
import {
  formatDateForRRule,
  generateRRule,
} from 'storybook-components/scheduling/RecurrenceGenerator/services/utils';

import { IRPProgress } from '../interfaces';
import { loadCsv } from '../services/loadCsv';
import { executeInParallelBatch } from '../utils/executeInParallelBatch';
import { UnitRPData } from './interfaces';
import { translations } from './translations';

const rentTypeSuffixes = {
  total: { suffix: '/0/2' },
  cold: { suffix: '/0/1' },
  temporaryChange: { suffix: '/0/3' },
  usage: { suffix: '/1/2' },
  heat: { suffix: '/1/3' },
  current: { suffix: '/1/4' },
  garage: { suffix: '/2/1' },
  parking: { suffix: '/2/2' },
  kitchen: { suffix: '/2/3' },
};

const mapRowsToUnitRPData = (csvRow: any): UnitRPData => (
  {
    propertyId: parseInt(csvRow.propertyId, 10),
    propertyExternalId: csvRow.propertyExternalId,
    unitId: parseInt(csvRow.unitId, 10),
    unitExternalId: csvRow.unitExternalId,
    occurrenceInterval: csvRow.occurrenceInterval,
    occurrence: csvRow.occurrence === 'Monat(e)' ? 'MONTHLY' : 'YEARLY',
    day: csvRow.day === 'Letzter Tag' ? -1 : csvRow.day,
    // @ts-ignore
    month: csvRow.month && getGermanMonthNumber(csvRow.month),
    startDate: csvRow.startDate,
    endDate: csvRow.endDate,
    type: csvRow.type === 'Pauschalmiete' ? RentPlanCreateDtoRentTypeEnum.FLAT_RATE : RentPlanCreateDtoRentTypeEnum.BREAKDOWN,
    total: csvRow.total && parseFloat(csvRow.total.replaceAll('.', '').replaceAll(',', '.')),
    cold: csvRow.cold && parseFloat(csvRow.cold.replaceAll('.', '').replaceAll(',', '.')),
    usage: csvRow.usage && parseFloat(csvRow.usage.replaceAll('.', '').replaceAll(',', '.')),
    heat: csvRow.heat && parseFloat(csvRow.heat.replaceAll('.', '').replaceAll(',', '.')),
    current: csvRow.current && parseFloat(csvRow.current.replaceAll('.', '').replaceAll(',', '.')),
    garage: csvRow.garage && parseFloat(csvRow.garage.replaceAll('.', '').replaceAll(',', '.')),
    parking: csvRow.parking && parseFloat(csvRow.parking.replaceAll('.', '').replaceAll(',', '.')),
    kitchen: csvRow.kitchen && parseFloat(csvRow.kitchen.replaceAll('.', '').replaceAll(',', '.')),
    vat: csvRow.vat && parseFloat(csvRow.vat.replaceAll('.', '').replaceAll(',', '.')),
  }
);

export const useRentPlansImportCsv = () => {
  const [properties, setProperties] = useState<PropertyLegacyDto[]>([]);
  const [dataValidationIssues, setDataValidationIssues] = useState<any[]>([]);
  const [unitsRPData, setUnitsRPData] = useState<UnitRPData[]>([]);
  const [rpProgress, setRpProgress] = useState<IRPProgress>({ started: false, finished: false, rpsImported: 0 });
  const { apiConfiguration } = useContext(AuthContext);
  const propertyControllerApi = new PropertyLegacyControllerApi(apiConfiguration('accounting'));
  const unitControllerApi = new UnitLegacyControllerApi(apiConfiguration('accounting'));
  const contractControllerApi = new ContractLegacyControllerApi(apiConfiguration('accounting'));
  const rentPlanControllerApi = new RentPlanControllerApi(apiConfiguration('accounting'));
  const { tl } = useContext(LanguageContext);


  useEffect(() => {
    propertyControllerApi.getAllPropertiesUsingGET({ page: 0, size: 1000 })
      .then((response) => {
        setProperties(response.content.filter(p => ([PropertyLegacyDtoAdministrationTypeEnum.MV, PropertyLegacyDtoAdministrationTypeEnum.SEV, PropertyLegacyDtoAdministrationTypeEnum.SEV_MV].includes(p.administrationType))));
      })
      .catch(() => {
        showNotification({
          key: 'loadPropertyListError',
          message: tl(translations.notifications.propertyLoadError),
          type: 'error',
        });
      });
  }, []);

  const loadRPDataFromCsvRows = (csvRows: any[]) => {
    const readUnitRPData: UnitRPData[] = [];
    const validationIssues: any[] = [];
    csvRows.forEach((csvRow) => {
      const unitRPData = mapRowsToUnitRPData(csvRow);

      readUnitRPData.push(unitRPData);
      // either property Id or external id must be present
      if (!unitRPData.propertyId && !unitRPData.propertyExternalId) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      if (unitRPData.propertyId && properties.map(p => p.id).indexOf(unitRPData.propertyId) < 0) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      if (unitRPData.propertyExternalId && properties.map(p => p.externalId).indexOf(unitRPData.propertyExternalId) < 0) {
        validationIssues.push({ message: tl(translations.validations.issues.property), row: csvRow._row - 1 });
      }
      // either unit id or external id must be present
      if (!unitRPData.unitExternalId && !unitRPData.unitId) {
        validationIssues.push({ message: tl(translations.validations.issues.unit), row: csvRow._row - 1 });
      }
      if (!unitRPData.occurrence) {
        validationIssues.push({ message: tl(translations.validations.issues.occurrence), row: csvRow._row - 1 });
      }
      if (!unitRPData.occurrenceInterval) {
        validationIssues.push({ message: tl(translations.validations.issues.occurrenceInterval), row: csvRow._row - 1 });
      }
      if (!unitRPData.day) {
        validationIssues.push({ message: tl(translations.validations.issues.occurrenceDay), row: csvRow._row - 1 });
      }
      if (!unitRPData.startDate) {
        validationIssues.push({ message: tl(translations.validations.issues.startDate), row: csvRow._row - 1 });
      }
      if (!unitRPData.type) {
        validationIssues.push({ message: tl(translations.validations.issues.type), row: csvRow._row - 1 });
      }
      if (unitRPData.occurrence === 'YEARLY' && !unitRPData.month) {
        validationIssues.push({ message: tl(translations.validations.issues.occurrenceMonth), row: csvRow._row - 1 });
      }
      if (unitRPData.type && unitRPData.type === RentPlanCreateDtoRentTypeEnum.FLAT_RATE && !unitRPData.total) {
        validationIssues.push({ message: tl(translations.validations.issues.total), row: csvRow._row - 1 });
      }
      if (unitRPData.type && unitRPData.type === RentPlanCreateDtoRentTypeEnum.BREAKDOWN && ![unitRPData.heat, unitRPData.cold, unitRPData.usage, unitRPData.current, unitRPData.garage, unitRPData.parking, unitRPData.kitchen].some(val => !isNil(val))) {
        validationIssues.push({ message: tl(translations.validations.issues.breakdown), row: csvRow._row - 1 });
      }
    });
    setDataValidationIssues(validationIssues);
    if (validationIssues.length) {
      setUnitsRPData([]);
    } else {
      setUnitsRPData(readUnitRPData);
    }
  };

  const loadCsvFile = (event) => {
    loadCsv(event.target.files, 0)
      .then(res => loadRPDataFromCsvRows(res))
      .catch((err) => {
        showNotification({ message: tl(translations.validations.errors) });
        console.error('Error while loading csv', err);
      });
  };

  const propertyRpData = useMemo(() => {
    const internalIds = unitsRPData.map(u => u.propertyId).every(id => !!id);
    const map = new Map();
    unitsRPData.forEach((data) => {
      const units = (internalIds ? map.get(data.propertyId) : map.get(data.propertyExternalId)) || [];
      const recurrencePattern = generateRRule({
        BYMONTH: data.month,
        BYMONTHDAY: data.day,
        FREQ: data.occurrence,
        INTERVAL: data.occurrenceInterval,
        UNTIL: data.endDate ? formatDateForRRule(moment(data.endDate, 'YYYY-MM-DD')) : undefined,
        COUNT: undefined,
      });
      units.push({
        unitExternalId: data.unitExternalId,
        unitId: data.unitId,
        rentType: data.type,
        startDate: data.startDate,
        recurrencePattern,
        // Amounts are net, need to calculate gross amounts = net * (100 + vat) / 100
        amounts: data.type === RentPlanCreateDtoRentTypeEnum.FLAT_RATE
          ? [{ name: 'total', amount: data.total * (100 + data.vat), vat: data.vat }]
          : [
            { name: 'cold', amount: (data.cold && data.vat) ? round2dec(data.cold * (100 + data.vat) / 100) : data.cold, vat: data.vat },
            { name: 'heat', amount: (data.heat && data.vat) ? round2dec(data.heat * (100 + data.vat) / 100) : data.heat, vat: data.vat },
            { name: 'usage', amount: (data.usage && data.vat) ? round2dec(data.usage * (100 + data.vat) / 100) : data.usage, vat: data.vat },
            { name: 'current', amount: (data.current && data.vat) ? round2dec(data.current * (100 + data.vat) / 100) : data.current, vat: data.vat },
            { name: 'garage', amount: (data.garage && data.vat) ? round2dec(data.garage * (100 + data.vat) / 100) : data.garage, vat: data.vat },
            { name: 'parking', amount: (data.parking && data.vat) ? round2dec(data.parking * (100 + data.vat) / 100) : data.parking, vat: data.vat },
            { name: 'kitchen', amount: (data.kitchen && data.vat) ? round2dec(data.kitchen * (100 + data.vat) / 100) : data.kitchen, vat: data.vat },
          ],
      });
      map.set(internalIds ? data.propertyId : data.propertyExternalId, units);
    });

    if (internalIds) {
      return Array.from(map.keys()).map(propId => ({
        units: map.get(propId),
        propertyId: propId,
      }));
    }
    return Array.from(map.keys()).map(propId => ({
      units: map.get(propId),
      propertyExternalId: propId,
    }));
  }, [unitsRPData]);

  const importRPs = async () => {
    setRpProgress({
      started: true,
      finished: false,
      rpsImported: 0,
    });
    try {
      await importAllRPs();
    } catch (e) {
      console.error('Import stopped', e, e.response);
      showNotification({
        key: 'importValidation-error',
        duration: 0,
        message: tl(translations.notifications.error.title),
        description: tl(translations.notifications.error.description),
        type: 'error',
      });
      setRpProgress(prev => ({ ...prev, finished: true }));
    }
  };

  const importAllRPs = async () => {
    const internalIds = propertyRpData.map(u => u.propertyId).every(id => !!id);
    await executeInParallelBatch(propertyRpData, 1, async (rpData) => {
      // find existing property
      const propertyDto: PropertyLegacyDto = internalIds
        ? properties.filter(p => p.id === rpData.propertyId)[0]
        : properties.filter(p => p.externalId === rpData.propertyExternalId)[0];

      // get units of that property
      const units: UnitLegacyDto[] = await unitControllerApi.getPropertyUnitsUsingGET1({ propertyId: propertyDto.id, isOwnedByWeg: false });

      // get contracts for the units
      const contracts: ContractProjectionDto[] = await contractControllerApi.getContractsUsingGET({
        propertyId: propertyDto.id,
        type: [GetContractsUsingGETTypeEnum.TENANT] as unknown as GetContractsUsingGETTypeEnum,
      });

      const rentPlanCreateDtos: RentPlanCreateDto[] = rpData.units.map((unit) => {
        const foundUnits = units.filter(u => (internalIds ? u.id === unit.unitId : u.externalId === unit.unitExternalId));
        if (foundUnits.length !== 1) {
          throw new Error(`Unit with with Id not found:${internalIds ? unit.unitId : unit.unitExternalId}`);
        }
        const foundUnit = foundUnits[0];
        let foundContracts = contracts.filter(c => c.unitId === foundUnit.id);
        if (foundContracts.length < 1) {
          throw new Error(`Contract for unit with Id not found:${internalIds ? unit.unitId : unit.unitExternalId}`);
        }
        foundContracts = sortUnitContracts(foundContracts);
        const foundContract = foundContracts[0];

        const rentPlanAmounts: RentPlanAmountDto[] = unit.amounts
          .filter(am => !!am.amount)
          .map(((am) => {
            const codeSuffix = rentTypeSuffixes[am.name].suffix;

            const rpAmount: RentPlanAmountDto = {
              categorisationAccountCode: `60${codeSuffix}`,
              counterpartAccountCode: foundContract.accountCode + codeSuffix,
              amount: am.amount,
              vatPercentage: am?.vat || 0,
            };

            return rpAmount;
          }));

        return ({
          propertyId: propertyDto.id,
          tenantContractId: foundContract.unitContractId,
          rentType: unit.rentType,
          startDate: unit.startDate,
          rentPlanAmounts,
          recurrencePattern: unit.recurrencePattern,
          paymentStartDate: unit.startDate,
        });
      });

      // eslint-disable-next-line no-return-await
      rentPlanCreateDtos.forEach(async rentPlanCreateDto => await rentPlanControllerApi.createRentPlanUsingPOST({ rentPlanCreateDto }));

      setRpProgress(prev => ({ ...prev, rpsImported: prev.rpsImported + 1 }));
    });
  };

  const percentage: number = useMemo(() => Math.round(rpProgress.rpsImported * 100 / Math.max(propertyRpData.length, 1)), [rpProgress, propertyRpData]);
  const alertDetails = useMemo(() => {
    if (unitsRPData.length > 0) {
      return {
        message: `${unitsRPData.length} ${tl(translations.validations.success)}`,
        type: 'success',
      };
    }
    return {
      message: tl(translations.description),
      type: 'info',
    };
  }, [unitsRPData]);

  return {
    propertiesLoaded: properties.length > 0,
    propertyList: properties,
    dataValidationIssues,
    importRPs,
    loadRPDataFromCsvRows,
    loadCsv,
    loadCsvFile,
    rpProgress,
    unitsRPData,
    percentage,
    alertDetails,
  };
};
