import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  AllocationItemDto, EconomicPlanControllerApi, EconomicPlanHouseMoneyControllerApi, HouseMoneyAmountV2Dto, HouseMoneyDto, UnitOwnerAccountBalanceDtoContractVatRelevanceEnum,
} from 'api/accounting';
import { useScoreStringSearch } from 'services/search/useScoreStringSearch';
import { calculateVatAmountFromGross, calculateVatPercentageFromGrossAndVatAmount, getDefaultVatPercentageForContract } from 'lib/vatUtils';
import useSmartTable from '../../../../elements/SmartTable/useSmartTable';
import DEFAULT_DATA, { DefaultDataInterface } from '../../../../lib/data';
import { showNotification } from '../../../../lib/Notification';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import {
  AccountAllocation, FEAccountBalanceDto, ExtendedBankTransaction, PostingText, ALLOCATION_TYPES,
} from '../../interfaces';
import { useUnitAccountTableColumns } from './useUnitAccountTableColumns';
import { translations } from '../../translations';
import { formatCurrency, formatDate } from '../../../../lib/Utils';
import { BankTransactionAllocationContext } from '../../services/BankTransactionAllocationContext';
import {
  PostingControllerApi,
  AccountWithLastBookingAmountDto,
  AccountControllerApi,
  UnitAccountDto,
  UnitOwnerAccountBalanceDto,
} from '../../../../api/accounting';
import { AuthContext } from '../../../../contexts/AuthContext';
import { usePropertyVatRatesContext } from './PropertyVatRatesContext';


const DEBOUNCE_WAIT_TIME = 200;


const stringifyFieldsRelevantForSearch = (
  accBalance: AccountWithLastBookingAmountDto,
  unitAccount: UnitAccountDto,
  ownerAccount: UnitOwnerAccountBalanceDto,
) => (
  `${accBalance.name ?? ''
  } ${unitAccount.unitNrSharingDeclaration ?? ''
  } ${accBalance.code ?? ''
  } ${accBalance.code?.replaceAll('/', '') ?? ''
  } ${ownerAccount.accountOwnerFullName
  } ${formatCurrency(accBalance.lastBookingAmount)
  } ${accBalance.propertyHrId
  } ${accBalance.propertyIdInternal
  } ${accBalance.propertyName
  }`
);


export const useUnitAccounts = ({
  allocationGroupId,
  transaction,
  bankTransactions,
  unitAllocationAmounts,
  showOnlyMarkedRows,
  statements,
  parentHeight,
  parentComponent,
  allocationProposals,
  setUnitTabDirty,
  selectedType,
}: {
  allocationGroupId: number | undefined
  transaction: ExtendedBankTransaction,
  bankTransactions: ExtendedBankTransaction[],
  unitAllocationAmounts: AccountAllocation[],
  statements?: PostingText[],
  showOnlyMarkedRows: boolean,
  parentHeight: number,
  parentComponent: Element | null,
  setUnitTabDirty: Function,
  allocationProposals: AllocationItemDto[],
  selectedType: ALLOCATION_TYPES,
}) => {
  const { tl } = useContext(LanguageContext);
  const [unitAccountMap, setUnitAccountMap] = useState(DEFAULT_DATA<Map<string, FEAccountBalanceDto>>({}));
  const [searchString, setSearchString] = useState('');
  const [unitHouseMoneyAndReserveFunds, setUnitHouseMoneyAndReserveFunds] = useState(DEFAULT_DATA<HouseMoneyDto[]>(undefined));
  const [appliedPreviousAllocationsOrProposal, setAppliedPreviousAllocationsOrProposal] = useState(false);
  const { allocationContentLoading } = useContext(BankTransactionAllocationContext);
  const { propertyVatRates } = usePropertyVatRatesContext('useUnitAccounts');
  /**
   * since the unit account list stays mounted even after we switch to the ServiceCompany tab
   * we need to reset the search string manually
   */
  useEffect(() => {
    if (selectedType === ALLOCATION_TYPES.SERVICE_COMPANY) {
      setSearchString('');
    }
    unitAccountTable.changeColumnVisibility('unitNrSharingDeclaration', selectedType !== ALLOCATION_TYPES.PRP_OWNER);
  }, [selectedType]);

  const { apiConfiguration } = useContext(AuthContext);
  const postingControllerApi = new PostingControllerApi(apiConfiguration('accounting'));
  const accountControllerApi = new AccountControllerApi(apiConfiguration('accounting'));
  const houseMoneyControllerApi = new EconomicPlanHouseMoneyControllerApi(apiConfiguration('accounting'));
  const economicPlanControllerApi = new EconomicPlanControllerApi(apiConfiguration('accounting'));

  const statementsRef = useRef<PostingText[] | undefined>([]);

  statementsRef.current = useMemo(() => statements, [statements]);

  const scoreStringSearch = useScoreStringSearch();

  /**
   * transaction.propertyList will be a different array if the user deselects the first tx from the list, so that would trigger
   * a re-calculation of this memoized value; the calculation of allocationGroupId depends on propertyList, so it is enough
   * to add allocationGroupId as a dependency to avoid re-calculation (and the subsequent re-fetches)
   */
  const propertyHrIds = useMemo(() => transaction.propertyList?.map(p => p.propertyHrId), [transaction?.allocationGroupId]);

  useEffect(() => {
    onLoadUnitHouseMoneyAndReserveAmounts();
  }, [propertyHrIds]);

  useEffect(() => {
    if (_.isEmpty(propertyHrIds) || !propertyVatRates.loaded || (!_.isEmpty(propertyVatRates.data) && !_.isEqual(propertyVatRates.data.map(vr => vr.propertyHrId).sort(), propertyHrIds.sort()))) return;
    if (allocationGroupId) {
      onLoadUnitAccountList();
    }
  }, [allocationGroupId, propertyHrIds, JSON.stringify(propertyVatRates.data)]);

  useEffect(() => {
    setAppliedPreviousAllocationsOrProposal(false);
  }, [selectedType]);

  useEffect(() => {
    if (!_.isEmpty(unitAccountMap.data) && !_.isEmpty(unitAllocationAmounts)) {
      try {
        setUnitAccountMap((oldList: { [index: string]: any }) => {
          const newState = _.cloneDeep(oldList.data!);

          const keys: { unitAccountKey: string, indexOfAllocationAmount: number }[] = unitAllocationAmounts.map(({
            externalRef,
            code,
          }, indexOfAllocationAmount) => {
            const unitId = externalRef?.replace(/UNIT/g, '');
            const unitAccountKey = `account-${unitId}-${code}`;

            return {
              unitAccountKey,
              indexOfAllocationAmount,
            };
          });

          keys.forEach(({ unitAccountKey }) => {
            if (newState[unitAccountKey]) {
              newState[unitAccountKey].sumToAllocate = undefined;
              newState[unitAccountKey].previouslyAllocatedAmount = undefined;
              newState[unitAccountKey].vatPercentageToAllocate = undefined;
              newState[unitAccountKey].previouslyAllocatedVatPercentage = undefined;
            }
          });

          const totalVatAmountPerUnitAccount: Map<String, number> = new Map();
          const totalPreviousVatAmountPerUnitAccount: Map<String, number> = new Map();

          keys.forEach(({ unitAccountKey, indexOfAllocationAmount }) => {
            const { sumToAllocate, vatPercentageToAllocate, postingTexts } = unitAllocationAmounts[indexOfAllocationAmount];
            if (newState[unitAccountKey]) {
              if (newState[unitAccountKey].sumToAllocate) {
                newState[unitAccountKey].sumToAllocate += Math.abs(sumToAllocate!);
                newState[unitAccountKey].shouldCalculateVatPercentage = vatPercentageToAllocate !== newState[unitAccountKey].vatPercentageToAllocate;
              } else {
                newState[unitAccountKey].sumToAllocate = Math.abs(sumToAllocate!);
                newState[unitAccountKey].vatPercentageToAllocate = vatPercentageToAllocate;
              }
              if (newState[unitAccountKey].previouslyAllocatedAmount) {
                newState[unitAccountKey].previouslyAllocatedAmount += Math.abs(sumToAllocate!);
              } else {
                newState[unitAccountKey].previouslyAllocatedAmount = Math.abs(sumToAllocate!);
              }
              if (totalVatAmountPerUnitAccount[unitAccountKey]) {
                totalVatAmountPerUnitAccount[unitAccountKey] += Math.abs(calculateVatAmountFromGross(sumToAllocate, vatPercentageToAllocate));
              } else {
                totalVatAmountPerUnitAccount[unitAccountKey] = Math.abs(calculateVatAmountFromGross(sumToAllocate, vatPercentageToAllocate));
              }
              if (totalPreviousVatAmountPerUnitAccount[unitAccountKey]) {
                totalPreviousVatAmountPerUnitAccount[unitAccountKey] += Math.abs(calculateVatAmountFromGross(sumToAllocate, vatPercentageToAllocate));
              } else {
                totalPreviousVatAmountPerUnitAccount[unitAccountKey] = Math.abs(calculateVatAmountFromGross(sumToAllocate, vatPercentageToAllocate));
              }
              newState[unitAccountKey].postingTexts = !_.isEmpty(postingTexts) ? postingTexts : statements;
            }
          });

          Object.keys(newState).forEach((unitAccountKey) => {
            // only calculate vat percentage if there are different ones for the same account
            if (newState[unitAccountKey].shouldCalculateVatPercentage) {
              newState[unitAccountKey] = {
                ...newState[unitAccountKey],
                vatPercentageToAllocate: newState[unitAccountKey].sumToAllocate !== undefined ? calculateVatPercentageFromGrossAndVatAmount(newState[unitAccountKey].sumToAllocate, totalVatAmountPerUnitAccount[unitAccountKey]) : undefined,
                previouslyAllocatedVatPercentage: newState[unitAccountKey].sumToAllocate !== undefined ? calculateVatPercentageFromGrossAndVatAmount(newState[unitAccountKey].sumToAllocate, totalPreviousVatAmountPerUnitAccount[unitAccountKey]) : undefined,
              };
            }
          });

          return oldList.load(newState);
        });
      } catch (e) {
        console.error('setUnitAccountMap: ', e);
      }
    }
  }, [unitAllocationAmounts, !_.isEmpty(unitAccountMap.data)]);

  const isAccountOnSelectedTab = (a: FEAccountBalanceDto) => {
    switch (selectedType) {
      case ALLOCATION_TYPES.UNIT_OWNER:
        return a.accountCode.startsWith('2000/');
      case ALLOCATION_TYPES.TENANT:
        return a.accountCode.startsWith('2001/');
      case ALLOCATION_TYPES.PRP_OWNER:
        return a.accountCode.startsWith('301/');
      default:
        return false;
    }
  };

  useEffect(() => {
    if (!appliedPreviousAllocationsOrProposal && !_.isEmpty(unitAccountMap.data) && !_.isEmpty(allocationProposals)) {
      try {
        setUnitAccountMap((oldList: { [index: string]: any }) => {
          let dirty = false;
          const newState = _.cloneDeep(oldList.data!);
          allocationProposals.forEach(({
            amount, vatPercentage, accountId,
          }) => {
            _.forEach(newState, (alloc: FEAccountBalanceDto) => {
              if (alloc.id === accountId && isAccountOnSelectedTab(alloc)) {
                alloc.sumToAllocate = Math.abs(amount);
                alloc.vatPercentageToAllocate = vatPercentage ?? 0;
                dirty = true;
              }
            });
          });
          setUnitTabDirty(dirty);
          setAppliedPreviousAllocationsOrProposal(true);
          return oldList.load(newState);
        });
      } catch (e) {
        console.error('setUnitAccountMap: ', e);
      }
    }
  }, [allocationProposals, unitAccountMap.data, appliedPreviousAllocationsOrProposal]);

  const applyFilters = (tempDs: FEAccountBalanceDto[]) => tempDs.filter(acc => !((showOnlyMarkedRows && !acc.childRows && acc.sumToAllocate === undefined)
    || (showOnlyMarkedRows && !acc.childRows && acc.sumToAllocate === 0 && [undefined, 0].includes(acc.previouslyAllocatedAmount))
    || (showOnlyMarkedRows && acc.childRows && acc.childRows.every((child: FEAccountBalanceDto) => child.sumToAllocate === undefined || (child.sumToAllocate === 0 && [undefined, 0].includes(child.previouslyAllocatedAmount))))
  ));

  const filteredDataSource = useMemo(() => {
    let tempDs: FEAccountBalanceDto[] = Object.values(unitAccountMap.data || {});

    tempDs = tempDs.filter((a) => {
      switch (selectedType) {
        case ALLOCATION_TYPES.UNIT_OWNER:
          return a.childRows?.[0].accountCode.startsWith('2000/') || a.accountCode?.startsWith('2000/');
        case ALLOCATION_TYPES.TENANT:
          return a.childRows?.[0].accountCode.startsWith('2001/') || a.accountCode?.startsWith('2001/');
        case ALLOCATION_TYPES.PRP_OWNER:
          return a.childRows?.[0].accountCode.startsWith('301/') || a.accountCode?.startsWith('301/');
        default:
          return false;
      }
    });

    const scored = scoreStringSearch(tempDs, searchString);

    // @ts-ignore
    tempDs = applyFilters(scored);

    // only sort them this way if the search bar is empty
    if (!searchString.trim()) {
      tempDs = tempDs.sort((a: FEAccountBalanceDto, b: FEAccountBalanceDto) => {
        if (a.propertyIdInternal === b.propertyIdInternal) {
          if (a.unitId === b.unitId) {
            // within the same unit we should sort the accounts first by isCurrentlyActiveAccount then by owners
            if (a.isCurrentlyActiveAccount === b.isCurrentlyActiveAccount) {
              return a.code?.localeCompare(b.code);
            }
            return b.childRows || (a.isCurrentlyActiveAccount !== undefined && b.isCurrentlyActiveAccount !== undefined && a.isCurrentlyActiveAccount < b.isCurrentlyActiveAccount) ? 1 : -1;
          }
          // If not in the same unit sort them by unitRank
          return a.unitId !== undefined && b.unitId !== undefined && a.unitRank! > b.unitRank! ? 1 : -1;
        }
        return a.propertyIdInternal && b.propertyIdInternal && a.propertyIdInternal! > b.propertyIdInternal! ? 1 : -1;
      });
    }

    if (unitHouseMoneyAndReserveFunds.data && !_.isEmpty(unitHouseMoneyAndReserveFunds.data)) {
      return tempDs.map((acc) => {
        if (acc.childRows) {
          const unitValues = unitHouseMoneyAndReserveFunds.data.filter(value => value.unitId === acc.unitId)[0];
          acc.houseMoneyAmount = unitValues?.houseMoneyAmounts?.reduce((agg: number, hm: HouseMoneyAmountV2Dto) => (hm.reserveFundId ? agg : agg + hm.amount), 0) || undefined;
          acc.reserveFundAmount = unitValues?.houseMoneyAmounts?.reduce((agg: number, hm: HouseMoneyAmountV2Dto) => (hm.reserveFundId ? agg + hm.amount : agg), 0) || undefined;
        }
        return acc;
      });
    }
    return tempDs;
  }, [unitAccountMap.data, unitHouseMoneyAndReserveFunds.data, showOnlyMarkedRows, searchString, selectedType]);

  const flatMapUnitAccount = (accounts: UnitAccountDto[]): Map<string, FEAccountBalanceDto> => {
    const flattenedData: any[] = accounts
      .flatMap((unitAccount: UnitAccountDto) => {
        const children: FEAccountBalanceDto[] = [];
        let currentOwnerName = '';
        if (unitAccount && unitAccount.unitOwnerAccountBalances) {
          unitAccount.unitOwnerAccountBalances.flatMap((ownerAccount: UnitOwnerAccountBalanceDto) => {
            if (ownerAccount && ownerAccount.accountBalances) {
              ownerAccount.accountBalances.forEach((accBalance: AccountWithLastBookingAmountDto) => {
                if (accBalance.isCurrentlyActiveAccount) {
                  currentOwnerName = ownerAccount.accountOwnerFullName!;
                }

                const newVal: FEAccountBalanceDto = {
                  id: accBalance.id,
                  code: accBalance.code,
                  name: accBalance.name,
                  isCurrentlyActiveAccount: accBalance.isCurrentlyActiveAccount,
                  propertyHrId: accBalance.propertyHrId,
                  propertyIdInternal: accBalance.propertyIdInternal,
                  propertyName: accBalance.propertyName,
                  lastBookingAmount: accBalance.lastBookingAmount,
                  accountCode: accBalance.code || '',
                  unitId: unitAccount.unitId,
                  unitRank: unitAccount.unitRank,
                  unitNrSharingDeclaration: unitAccount.unitNrSharingDeclaration,
                  accountOwnerFullName: ownerAccount.accountOwnerFullName,
                  vatRelevance: ownerAccount.contractVatRelevance,
                  unitAccountKey: `account-${unitAccount.unitId}-${accBalance.code}`,
                  searchValue: stringifyFieldsRelevantForSearch(accBalance, unitAccount, ownerAccount),
                };
                children.push(newVal);
              });
            }
          });
          const parentAccountBalance = {
            unitId: unitAccount.unitId,
            unitRank: unitAccount.unitRank,
            unitNrSharingDeclaration: unitAccount.unitNrSharingDeclaration,
            accountOwnerFullName: currentOwnerName,
            unitAccountKey: `unit-${unitAccount.unitId}`,
            childRows: children,
            id: `unit-${unitAccount.unitId}`,
            propertyHrId: children[0]?.propertyHrId,
            propertyIdInternal: children[0]?.propertyIdInternal,
            propertyName: children[0]?.propertyName,
            searchValue: children.map(ch => ch.searchValue).join(' '),
          };

          const vatRatesForAccount = propertyVatRates.data?.find(vr => vr.propertyHrId === parentAccountBalance.propertyHrId);
          return [parentAccountBalance, ...(children.map((c) => {
            const defaultVatPercentage = getDefaultVatPercentageForContract(vatRatesForAccount, c.vatRelevance);
            return ({
              ...c,
              vatRatesForAccount,
              defaultVatPercentage,
            });
          }))];
        }
        return [];
      });

    return flattenedData.map((row: any) => {
      const x: { [index: string]: any } = {};
      x[row.unitAccountKey] = row;
      return x;
    }).reduce((acc, x) => Object.assign(acc, x), {}) as Map<string, FEAccountBalanceDto>;
  };

  const onLoadUnitAccountList = async () => {
    if (_.isEmpty(propertyHrIds) || transaction.amount === undefined || !transaction.bankBookingDate) return;
    setUnitAccountMap(prev => prev.startLoading());

    try {
      const debitTransaction = transaction.amount > 0;
      const bookingDate = formatDate(moment(transaction.bankBookingDate), 'YYYY-MM-DD');
      const response = await accountControllerApi.getUnitAccountBalancesUsingGET({ bookingDate, debitTransaction, propertyHrIds });
      setUnitAccountMap((oldList: DefaultDataInterface<Map<string, FEAccountBalanceDto>>) => oldList.load(flatMapUnitAccount(response)));
    } catch (err) {
      setUnitAccountMap(oldList => oldList.failed());
      showNotification({
        key: 'loadUnitAccountListError',
        message: tl(translations.notifications.loadUnitAccountError.message),
        description: tl(translations.notifications.loadUnitAccountError.description),
        type: 'warning',
      });
    }
  };

  const amountChanged = (previousAmount?: number, currentAmount?: number) => (previousAmount || currentAmount) && (previousAmount !== currentAmount);
  const vatPercentageChanged = (previousVatPercentage?: number, currentVatPercentage?: number) => (previousVatPercentage || currentVatPercentage) && (previousVatPercentage !== currentVatPercentage);

  const changesInAllocatedAmounts = (newState: any) => {
    let foundChanges = false;
    _.forEach(newState, (alloc: FEAccountBalanceDto) => {
      if (amountChanged(alloc.previouslyAllocatedAmount, alloc.sumToAllocate) || vatPercentageChanged(alloc.previouslyAllocatedVatPercentage, alloc.vatPercentageToAllocate)) {
        foundChanges = true;
      }
    });
    return foundChanges;
  };

  // allocation was reverted or changed OR new allocation was introduced OR actual changes or reverting happened before this change
  const shouldSetDirty = (record: FEAccountBalanceDto, newState: Map<string, FEAccountBalanceDto>) => amountChanged(record.previouslyAllocatedAmount, record.sumToAllocate)
    || vatPercentageChanged(record.previouslyAllocatedVatPercentage, record.vatPercentageToAllocate)
    || changesInAllocatedAmounts(newState);

  const onChangeAllocatedAmount = (unitAccountKey: string, value?: number) => {
    setUnitAccountMap((oldList: { [index: string]: any }) => {
      try {
        const newState = _.cloneDeep(oldList.data!);
        newState[unitAccountKey].sumToAllocate = value; // lastBookingAmount is the suggestedValue handled by CurrencyInputWithCheckbox
        newState[unitAccountKey].postingTexts = newState[unitAccountKey].postingTexts ?? statementsRef.current;
        newState[unitAccountKey].vatPercentageToAllocate = newState[unitAccountKey].vatPercentageToAllocate ?? newState[unitAccountKey].defaultVatPercentage;
        const tempDirty = shouldSetDirty(newState[unitAccountKey], newState);
        setUnitTabDirty(tempDirty);
        return oldList.load(newState);
      } catch (e) {
        console.error('onChangeAllocatedAmount: ', e);
        setUnitTabDirty(false);
        return oldList;
      }
    });
  };

  const onChangeVatPercentage = (unitAccountKey: string, value?: number) => {
    setUnitAccountMap((oldList: { [index: string]: any }) => {
      try {
        const newState = _.cloneDeep(oldList.data!);
        newState[unitAccountKey].vatPercentageToAllocate = value;
        const tempDirty = shouldSetDirty(newState[unitAccountKey], newState);
        setUnitTabDirty(tempDirty);
        return oldList.load(newState);
      } catch (e) {
        console.error('onChangeVatPercentage: ', e);
        setUnitTabDirty(false);
        return oldList;
      }
    });
  };

  const onChangePostingText = async (record: any, values: PostingText[]) => {
    const { unitAccountKey } = record;
    const existingPostingTexts = values.filter(v => v.postingId && v.postingItemId).map(existingPostingText => ({
      postingId: existingPostingText.postingId,
      postingItemId: existingPostingText.postingItemId,
      bookingText: existingPostingText.postingText,
    }));

    if (!_.isEmpty(existingPostingTexts)) {
      try {
        await postingControllerApi.updatePostingItemBookingTextUsingPUT({ bookingText: existingPostingTexts });
        setUnitAccountMap((oldList: { [index: string]: any }) => {
          try {
            const newState = _.cloneDeep(oldList.data!);
            newState[unitAccountKey].postingTexts = values;
            return oldList.load(newState);
          } catch (e) {
            console.error('onChangePostingText: ', e);
            return oldList;
          }
        });
      } catch (err) {
        console.error('Failed to update posting texts', err);
      }
    } else {
      setUnitAccountMap((oldList: { [index: string]: any }) => {
        try {
          const newState = _.cloneDeep(oldList.data!);
          newState[unitAccountKey].postingTexts = values;
          setUnitTabDirty(shouldSetDirty(newState[unitAccountKey], newState));
          return oldList.load(newState);
        } catch (e) {
          console.error('onChangePostingText: ', e);
          setUnitTabDirty(false);
          return oldList;
        }
      });
    }
  };


  const onLoadUnitHouseMoneyAndReserveAmounts = () => {
    const propertyIds = transaction.propertyList?.map(p => p.propertyId);

    if (_.isEmpty(propertyIds)) return;
    setUnitHouseMoneyAndReserveFunds(prev => prev.startLoading());
    const economicPlanPromises = propertyIds.map(propertyId => economicPlanControllerApi.getAllUsingGET({
      propertyId,
      atDate: formatDate(new Date(), 'YYYY-MM-DD'),
    }));

    Promise.all(economicPlanPromises)
      .then((activeEconomicPlans) => {
        const hmPromises = activeEconomicPlans.map(aep => aep.content[0])?.map(ep => houseMoneyControllerApi.getHouseMoneyAmountsUsingGET({ economicPlanId: ep?.id }));

        Promise.all(hmPromises)
          .then(((response) => {
            setUnitHouseMoneyAndReserveFunds(prev => prev.load(response.flatMap(r => r)));
          }))
          .catch((e) => {
            console.error('Failed to load house money and reserve amounts', e);
            setUnitHouseMoneyAndReserveFunds(prev => prev.failed(e));
          });
      })
      .catch((e) => {
        console.error('Failed to load house money and reserve amounts', e);
        setUnitHouseMoneyAndReserveFunds(prev => prev.failed(e));
      });
  };


  const resetPreviouslyAllocatedAmounts = () => {
    setUnitAccountMap((oldList: { [index: string]: any }) => {
      const newState = _.cloneDeep(oldList.data!);

      Object.keys(newState).forEach((key: string) => {
        newState[key].previouslyAllocatedAmount = newState[key].sumToAllocate;
        newState[key].previouslyAllocatedVatPercentage = newState[key].vatPercentageToAllocate;
      });

      if (oldList.loading) {
        return oldList.load(newState).startLoading();
      }
      return oldList.load(newState);
    });
  };

  const clearAllocationData = () => {
    setUnitAccountMap((oldList: { [index: string]: any }) => {
      const newState = _.cloneDeep(oldList.data!);

      Object.keys(newState).forEach((key: string) => {
        delete newState[key].previouslyAllocatedAmount;
        delete newState[key].sumToAllocate;
        delete newState[key].postingTexts;
        delete newState[key].previouslyAllocatedVatPercentage;
        delete newState[key].vatPercentageToAllocate;
      });

      return oldList.load(newState);
    });
  };

  const unitAccountTable = useSmartTable({
    tableName: 'unitList',
    columns: useUnitAccountTableColumns({
      onChangeAllocatedAmount,
      onChangeVatPercentage,
      onChangePostingText,
      isDebit: (transaction.amount && transaction.amount > 0) || false,
      statements: statementsRef.current,
      bankTransactions,
    }),
    dataSource: filteredDataSource || [],
    contentLoading: unitAccountMap.loading || allocationContentLoading || unitHouseMoneyAndReserveFunds.loading,
    rowKey: 'unitAccountKey',
    virtualize: true,
    hideColumnSelector: true,
    rowClassName: record => (record.childRows ? 'parent-row' : 'child-row'),
    parentHeight,
    parentComponent,
  });

  const onSearch = _.debounce((input: string) => {
    setSearchString(input);
  }, DEBOUNCE_WAIT_TIME);


  return {
    onSearch,
    unitAccountTable,
    unitAccountMap,
    onChangeAllocatedAmount,
    resetPreviouslyAllocatedAmounts,
    setAppliedPreviousAllocationsOrProposal,
    setUnitAccountMap,
    clearAllocationData,
  };
};
