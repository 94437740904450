import { ConfigProvider, Table } from 'antd';
import { opsCostTranslation } from 'pages/OpsCostReport/translations';
import SubSectionWrapper from 'storybook-components/sections/SubSectionWrapper/SubSectionWrapper';
import TableContentLoadingIndicator from 'elements/SmartTable/components/TableContentLoadingIndicator/TableContentLoadingIndicator';
import EmptyTableMultilineMessage from 'storybook-components/table/EmptyTableMultilineMessage/EmptyTableMultilineMessage';
import { isEmpty } from 'lodash';
import { useIndividualTenantCostsManualTotalFromStore, useOtherOrTransferableCostsManualTotalFromStore } from 'pages/OpsCostReport/OpsCostReportEditing/services/stores/opsCostReportManualAmountsStore/selectorsOpsCostReportManualAmounts';
import { useTotalExpensesSection as hook } from './useTotalExpensesSection';
import ExpenseTableSummary from '../components/ExpenseTableSummary';


type ReturnTypeOfHook = ReturnType<typeof hook>;
export type GetTotalExpensesSectionProps = ReturnTypeOfHook['getTotalExpensesSectionProps'];
type ReturnTypeOfTotalExpensesHook = ReturnType<GetTotalExpensesSectionProps>


interface OtherOpsCostsSectionProps {
  sectionNumber: number;
  tl: any;
  useTotalExpensesSection: ReturnTypeOfTotalExpensesHook;
  contractId: number;
}


const TotalExpensesSection = ({
  sectionNumber,
  tl,
  useTotalExpensesSection,
  contractId,
}: OtherOpsCostsSectionProps) => {
  const {
    columns,
    getTotalExpensesDataSource,
    getTotalExpensesSummaryData,
    loading,
    isWegPropertySelected,
  } = useTotalExpensesSection;


  const otherOrTransferableCostsManualTotalFromStore = useOtherOrTransferableCostsManualTotalFromStore(contractId);
  const individualTenantCostsManualTotalFromStore = useIndividualTenantCostsManualTotalFromStore(contractId);
  const totalExpensesDataSource = getTotalExpensesDataSource(otherOrTransferableCostsManualTotalFromStore, individualTenantCostsManualTotalFromStore);

  // if the table is empty we need to set the height, otherwise the Empty component will not be visible
  const tableHeight = isEmpty(totalExpensesDataSource) ? { y: 200 } : undefined;

  const subsectionNumber = isWegPropertySelected ? 4 : 3;

  return (
    <SubSectionWrapper title={`${sectionNumber}.${subsectionNumber} ${tl(opsCostTranslation.report.sections.expensesSection.totalExpensesSubSection.title)}`}>
      <ConfigProvider renderEmpty={() => <EmptyTableMultilineMessage description={tl(opsCostTranslation.report.emptyTable.common)} />}>
        <Table
          columns={columns}
          dataSource={totalExpensesDataSource}
          rowKey="position"
          pagination={false}
          scroll={tableHeight}
          loading={{
            indicator: <TableContentLoadingIndicator />,
            spinning: loading,
          }}
          summary={() => (
            <ExpenseTableSummary
              title={tl(opsCostTranslation.report.sections.expensesSection.totalExpensesSubSection.total)}
              totalSummaryData={getTotalExpensesSummaryData(otherOrTransferableCostsManualTotalFromStore, individualTenantCostsManualTotalFromStore)}
              nrTotalColumns={columns.length}
              addPlaceholderLastColumn={false}
            />
          )}
        />
      </ConfigProvider>
    </SubSectionWrapper>
  );
};

export default TotalExpensesSection;
