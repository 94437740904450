import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useBankTransactionListDrawerContext } from 'pages/BankTransactions/services/BankTransactionListContext';
import { BankTransactionAllocationContext } from '../../services/BankTransactionAllocationContext';
import { showNotification } from '../../../../lib/Notification';
import { translations } from '../../translations';
import { LanguageContext } from '../../../../contexts/LanguageContext';
import {
  AccountAllocationDto,
  AccountAllocationRequest,
  AllocationItemDto,
  BankTransactionControllerApi,
  BankTransactionDto,
  BankTransactionProjectionDtoTransactionPaymentStatusEnum,
} from '../../../../api/accounting';
import {
  AccountAllocation, FEAccountBalanceDto, PostingText,
} from '../../interfaces';
import { formatCurrencyToNumber } from '../../../../lib/Utils';
import { AuthContext } from '../../../../contexts/AuthContext';
import { useHgaValidation } from './useHgaValidation';


export interface UpdateListElementFunction {
  (
    shouldOpenNext: boolean,
    originalGroupId: number,
    groupIdForAllocatedTransactions: number,
    transactionIds: number[],
    status: string,
    remainingAmount: number,
    allocatedInvoiceIds?: number[],
    unallocatedInvoiceIds?: number[],
  ): void;
}


export default function useTransactionAllocation({
  updateListElement,
  statements,
}: { updateListElement: UpdateListElementFunction, statements: PostingText[] }) {
  const [allocationProposals, setAllocationProposals] = useState<AllocationItemDto[]>([]);
  const [unitTabDirty, setUnitTabDirty] = useState(false);
  const { tl } = useContext(LanguageContext);

  const {
    selectedTransactions,
  } = useBankTransactionListDrawerContext('useSelectedTransactions');

  const {
    setAllocationContentLoading,
    allocatedPropertyHrIdsOnServiceCompanyTab,
    setAllocatedPropertyHrIdsOnServiceCompanyTab,
    unitAllocationAmounts,
    setUnitAllocationAmounts,
  } = useContext(BankTransactionAllocationContext);

  const { apiConfiguration } = useContext(AuthContext);
  const bankTransactionControllerApi = new BankTransactionControllerApi(apiConfiguration('accounting'));

  useEffect(() => {
    setAllocatedPropertyHrIdsOnServiceCompanyTab([]);
    setAllocationProposals([]);
    setUnitAllocationAmounts([]);
  }, [selectedTransactions[0]?.allocationGroupId]);

  useEffect(() => {
    if (!_.isEmpty(selectedTransactions)) {
      if (selectedTransactions[0]?.transactionPaymentStatus === BankTransactionProjectionDtoTransactionPaymentStatusEnum.UNASSIGNED) {
        setUnitAllocationAmounts([]);
        onLoadTransactionAllocationProposals();
      } else {
        setAllocationProposals([]);
        onLoadTransactionAllocationAmounts();
      }
    }
  }, [selectedTransactions[0]?.allocationGroupId, selectedTransactions[0]?.transactionPaymentStatus, selectedTransactions[0]?.remainingAmount]);

  const isUnitAccount = (account: AccountAllocationDto) => account.accountExternalRef && account.accountExternalRef.includes('UNIT');
  const isPrpOwnerAccount = (account: AccountAllocationDto) => !account.accountExternalRef && account.accountCode.startsWith('301/');

  const onLoadTransactionAllocationAmounts = async () => {
    if (!selectedTransactions[0] || !selectedTransactions[0].bankTransactionId) return;

    const bankTransactionIds = selectedTransactions.map((bt: any) => bt.bankTransactionId);
    try {
      setAllocationContentLoading(true);
      const allocations: { [bankTransactionId: string]: Array<AccountAllocationDto> } = await bankTransactionControllerApi.getAllocationAmountsUsingGET({ bankTransactionIds });

      const tmpUnitAllocationAmounts = Object.values(allocations)[0]
        .filter((acc: AccountAllocationDto) => isUnitAccount(acc) || isPrpOwnerAccount(acc))
        .map((acc: AccountAllocationDto) => ({
          id: acc.accountId,
          code: acc.accountCode,
          propertyHrId: acc.propertyHrId,
          externalRef: isPrpOwnerAccount(acc) ? `UNIT-${acc.propertyId}` : acc.accountExternalRef,
          sumToAllocate: acc.sumToAllocate ? Math.abs(acc.sumToAllocate) : 0,
          vatPercentageToAllocate: acc.vatPercentageToAllocate || 0,
          postingTexts: Object.entries(allocations).flatMap(([bankTransactionId, accountAllocationDtos]) => accountAllocationDtos.filter((acc2: AccountAllocationDto) => (isUnitAccount(acc2) || isPrpOwnerAccount(acc2)) && acc2.accountId === acc.accountId)
            .map(allocDto => ({
              bankTransactionId: parseInt(bankTransactionId, 10),
              postingText: allocDto.postingText,
              postingId: allocDto.postingId,
              postingItemId: allocDto.postingItemId,
            }))),
        }));

      const manualAllocationPropertyHrIds = Object.values(allocations)[0]
        .filter((acc: AccountAllocationDto) => !isUnitAccount(acc) && !isPrpOwnerAccount(acc) && acc.propertyHrId)
        .map((acc: AccountAllocationDto) => acc.propertyHrId);

      setUnitAllocationAmounts(tmpUnitAllocationAmounts);
      setAllocatedPropertyHrIdsOnServiceCompanyTab(manualAllocationPropertyHrIds);
    } catch (e) {
      showNotification({
        key: 'loadAllocationAmountsError',
        message: tl(translations.notifications.loadPreviousAllocationsError.message),
        type: 'error',
      });
    } finally {
      setAllocationContentLoading(false);
    }
  };

  const onLoadTransactionAllocationProposals = async () => {
    try {
      const response: AllocationItemDto[] = await bankTransactionControllerApi.getAllocationProposalUsingGET({
        bankTransactionId: selectedTransactions[0]?.bankTransactionId!,
      });
      setAllocationProposals(response);
    } catch (error) {
      showNotification({
        key: 'loadAllocationProposalError',
        message: tl(translations.notifications.loadAllocationProposalsError.message),
        type: 'error',
      });
    }
  };

  const propertyHrIds = useMemo(() => selectedTransactions[0]?.propertyList?.map(p => p.propertyHrId) || [], [selectedTransactions[0]?.propertyList]);


  function displaySuccessNotification(remainingAmount: number, onTabChange?: boolean) {
    if (onTabChange) {
      showNotification({
        key: 'allocationSuccess',
        message: tl(translations.notifications.successOnTabChange),
        type: 'success',
      });
    } else if (remainingAmount === 0) {
      showNotification({
        key: 'allocationSuccess',
        message: tl(translations.notifications.success),
        type: 'success',
      });
    } else {
      showNotification({
        key: 'allocationPartialSuccess',
        message: tl(translations.notifications.partialSuccess),
        type: 'success',
      });
    }
  }

  const mapToAccountAllocationItemWithMultiplePostings = (account: FEAccountBalanceDto | AccountAllocation | AccountAllocationDto) => ({
    sumToAllocate: typeof account.sumToAllocate === 'string' ? formatCurrencyToNumber(account.sumToAllocate) : (account.sumToAllocate ?? 0),
    vatPercentageToAllocate: account.vatPercentageToAllocate || 0,
    // @ts-ignore
    accountId: account.id ?? account.accountId!,
    // @ts-ignore
    id: account.id ?? account.accountId!,
    // @ts-ignore
    postingTexts: account.postingTexts || statements,
  });

  const mapToAccountAllocationItem = (transactionId: number, accounts: any) => accounts.map((account: any) => {
    const postingText = account.postingTexts.filter((pt: PostingText) => pt.bankTransactionId === transactionId)[0]?.postingText || '';
    return ({
      sumToAllocate: account.sumToAllocate,
      vatPercentageToAllocate: account.vatPercentageToAllocate || 0,
      accountId: account.accountId ?? account.id,
      postingText,
    });
  });

  const { checkIfHgaIsClosed } = useHgaValidation();

  const onAllocateAccounts = async (isUnitTab: boolean, selectedAccounts: AccountAllocation[], onSuccess: Function, onTabChange?: boolean, callback?: () => void) => {
    const accountPropertyHrIds = _.uniq(selectedAccounts.filter(a => !!a.sumToAllocate && a.sumToAllocate > 0)?.map(a => a.propertyHrId));

    if (accountPropertyHrIds.length > 1 || (!_.isEmpty(allocatedPropertyHrIdsOnServiceCompanyTab) && accountPropertyHrIds[0] !== allocatedPropertyHrIdsOnServiceCompanyTab[0])) {
      showNotification({
        key: 'allocationToDifferentPropertiesError',
        message: tl(translations.notifications.allocationToDifferentPropertiesError),
        type: 'error',
      });
      setAllocationContentLoading(false);
      return;
    }

    const unitAccounts = isUnitTab ? selectedAccounts.filter(a => !!a.sumToAllocate && a.sumToAllocate > 0).map((a: AccountAllocation) => mapToAccountAllocationItemWithMultiplePostings(a))
      : (unitAllocationAmounts || []).filter(a => !!a.sumToAllocate && a.sumToAllocate > 0).map(a => mapToAccountAllocationItemWithMultiplePostings(a));

    const propertyHrId = !_.isEmpty(selectedAccounts.filter(a => !!a.sumToAllocate && a.sumToAllocate > 0)) ? selectedAccounts.filter(a => !!a.sumToAllocate && a.sumToAllocate > 0)[0]?.propertyHrId : unitAllocationAmounts?.filter(ua => ua.propertyHrId)?.[0].propertyHrId;

    const onProceed = async () => {
      if (!_.isEmpty(selectedTransactions)) {
        try {
          const originalGroupId = selectedTransactions[0].allocationGroupId!;
          const bankTransactionIds = selectedTransactions.map(tx => tx.bankTransactionId!);
          const requestMap: { [key: string]: AccountAllocationRequest } = {};
          const allocationItems = [...unitAccounts];
          bankTransactionIds.forEach((id: number) => {
            const propertyId = selectedTransactions[0].propertyList?.filter(p => p.propertyHrId === propertyHrId)[0]?.propertyId;
            const mappedAllocationItem = mapToAccountAllocationItem(id, allocationItems);
            requestMap[id] = {
              propertyHrId,
              propertyId,
              accountAllocationItems: mappedAllocationItem,
            };
          });
          // The BE was updated and now the allocated items will always have new groupId and the unallocatedOnes (if any) will have the original groupId.
          const unallocatedTransactionsGroupId = selectedTransactions[0]?.allocationGroupId!;
          const bankTransactionDtos: Array<BankTransactionDto> = await bankTransactionControllerApi.bookManualTransactionsUsingPOST({
            allocationGroupId: unallocatedTransactionsGroupId,
            accountAllocationRequests: requestMap,
          });

          const transactionResponse = bankTransactionDtos![0];
          if (!_.isNil(transactionResponse?.remainingAmount)) {
            displaySuccessNotification(transactionResponse.remainingAmount, onTabChange);
          }

          const groupIdForAllocatedTransactions = transactionResponse.allocationGroupId!;

          const shouldOpenNext = !onTabChange && (transactionResponse?.remainingAmount === 0 || transactionResponse.remainingAmount === transactionResponse.amount);
          updateListElement(shouldOpenNext, originalGroupId, groupIdForAllocatedTransactions, selectedTransactions.map(bt => bt.bankTransactionId!), transactionResponse.transactionPaymentStatus!, transactionResponse.remainingAmount!);
          onSuccess(transactionResponse.remainingAmount!);
          callback?.();
        } catch (error) {
          console.error(error);
          if (onTabChange) {
            showNotification({
              key: 'allocationError',
              message: tl(translations.notifications.errorOnTabChange),
              type: 'error',
            });
          } else {
            showNotification({
              key: 'allocationError',
              message: tl(translations.notifications.saveError),
              type: 'error',
            });
          }
        } finally {
          // reset dirty flag even if allocation failed, because data is reloaded
          if (isUnitTab) setUnitTabDirty(false);
          setAllocationContentLoading(false);
        }
      }
    };


    const propertyId = selectedTransactions[0]?.propertyList?.find(p => p.propertyHrId === propertyHrId)?.propertyId;
    const economicYear = selectedTransactions[0]?.bankBookingDate ? moment(selectedTransactions[0]?.bankBookingDate, 'YYYY-MM-DD').year() : undefined;
    checkIfHgaIsClosed(propertyId, propertyHrId, economicYear, onProceed, () => setAllocationContentLoading(false));
  };

  const transactionAmount = selectedTransactions[0]?.amount || 0;

  return {
    propertyHrIds,
    transactionAmount,
    onAllocateAccounts,
    allocationProposals,
    unitTabDirty,
    setUnitTabDirty,
  };
}
